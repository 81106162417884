footer {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    height: fit-content;
    /* margin-top: 100px; */
    /* border: 2px solid #000; */
    padding-top: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    /* background-image: url('./images/asset4.png'); */
    background-repeat: no-repeat;
    background-position: center top;
    border-top: 5px solid #b81104;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

footer .row {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.footer-col img {
    width: 80px;
}

.footer-col h2 {
    cursor: pointer;
    color: #000;
    font-size: 25px;
}

.footer-col h2 span {
    color: #b81104;
}

.footer-col p {
    color: #687799;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

.footer-col .contact-col p {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;
    /* border: 2px solid #000; */
}

.footer-col .contact-col p>p {
    margin: 0px;
}

.footer-col .contact-col p i {
    color: #b81104;
}

.footer-col {
    height: fit-content;
    width: 20%;
    padding: 0 15px;
    line-height: 30px;
    /* border: 2px solid #ccc; */
}

.footer-col a,
.footer-col span {
    color: #687799;
    display: flex;
    font-weight: 450;
    /* text-transform: capitalize; */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.footer-col a:hover,
.footer-col span:hover {
    transform: translateX(5px);
    color: #b81104;
}

.footer-col:last-child a:hover {
    transform: translateY(-5px);
}

.footer-col-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    gap: 20px;
}

.footer-col-logo h2 {
    display: none;
}

.footer-col h4 {
    font-size: 18px;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 600;
    position: relative;
}

.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #b81104;
    height: 3px;
    box-sizing: border-box;
    width: 50px;
    border-radius: 55px;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #000;
    text-decoration: none;
    font-weight: 300;
    color: #000;
    display: block;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #000;
    padding-left: 8px;
}

.footer-col .social-links {
    display: flex;
}

.footer-col .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #000;
    transition: all 0.5s ease;
}

.footer-col .social-links a i {
    font-size: 20px;
}

.footer-col .social-links a:hover {
    color: #fff;
    background-color: #b81104;
}

.footer-col .news-letter {
    width: 100%;
    height: fit-content;
    display: flex;
    border-radius: 10px;
    background-color: #eef1f5;
}

.footer-col .news-letter input {
    height: 40px;
    width: 150px;
    background-color: #eef1f5;
    border: none;
    border-top-left-radius: 55px;
    border-bottom-left-radius: 55px;
    padding-left: 10px;
    outline: none;
}

.footer-col .news-letter button {
    height: 40px;
    background-color: #b81104;
    color: #fff;
    border: none;
    font-weight: 600;
    border-radius: 10px;
    padding: 0px 8px;
    outline: none;
    cursor: pointer;
}

.footer-copyright {
    height: 40px;
    width: 100%;
    background-color: #480607;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.footer-copyright-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.footer-copyright-left img {
    width: 50px;
}

.footer-copyright p {
    font-size: 15px;
}

.footer-copyright-right {
    font-size: 15px;
}

.footer-copyright-right a,
.footer-copyright-left a {
    color: #fff;
    text-decoration: underline;
}

/* Responsive */
/* 767px and 1024px */
@media (min-width: 767px) and (max-width: 1024px) {

    .news-letter {
        flex-direction: column;
    }

}

/* 320px to 767px */
@media (min-width: 320px) and (max-width: 767px) {
    footer .row {
        flex-direction: column;
        padding-left: 0px;
    }

    .footer-col-logo h2 {
        display: block;
        font-size: 20px;
        text-align: start;
    }

    .footer-col {
        width: 100%;
        padding-bottom: 20px;
    }

    .footer-copyright {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px 10px;
        text-align: center;
    }

    .footer-copyright-left,
    .footer-copyright-right {
        text-align: center;
        display: block;
    }

    .footer-col .news-letter input {
        width: 100%;
        padding-left: 20px;
    }

    .footer-col .news-letter button {
        width: 150px;
    }
}