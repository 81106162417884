.explore-rooms-section {
    width: 100%;
    height: fit-content;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F6F6F6;
}

.explore-rooms-header {
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.explore-rooms-header-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.explore-rooms-header-left h2 {
    font-size: 30px;
    font-weight: 750;
}

.explore-rooms-header-left h4 {
    font-size: 14px;
    font-weight: 600;
    color: #A0A0A0;
}

.explore-rooms-header-right button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: #fff;
    border: 1.5px solid #b81104;
    transition: all 0.2s ease-in;
}

.explore-rooms-header-right button>i {
    font-size: 25px;
    color: #b81104;
}

.explore-rooms-header-right button:hover i {
    color: #fff;
}

.explore-rooms-header-right button:hover {
    background-color: #b81104;
    color: #fff;
}

.explore-rooms-cards {
    padding: 40px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

}

/* Responsive */
@media (max-width:480px) {
    .explore-rooms-header {
        height: fit-content;
        flex-direction: column;
        gap: 20px;
    }

    .explore-rooms-header-left h2 {
        font-size: 25px;
    }

    .explore-rooms-header-right button {
        font-size: 14px;
        padding: 5px 10px;
        font-weight: 500;
    }

    .explore-rooms-cards {
        padding: 30px 0px;
    }

    .explore-rooms-cards {
        
    }

    .explore-rooms-section {
        padding: 10px;
    }
}