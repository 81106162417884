.hero-section {
    width: 100%;
    height: 80vh;
    /* Full viewport height */
    max-width: 1440px;
    margin: auto;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.video-background {
    position: relative;
    width: 100%;
    height: 100%;
    /* Full section height */
    overflow: hidden;
}

.video-background video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the video covers the area proportionately */
    z-index: -1;
    /* Places the video behind other content */
}

.overlay-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    margin-top: 100px;
}

.overlay-content h1 {
    text-transform: uppercase;
    font-size: 40px;
    font-family: 'SF Espresso Shack';
    opacity: 30%;
}

.video-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 60%,
            rgba(0, 0, 0, 0.65) 100%);
    z-index: 0;
}

/* Responsive */
@media (max-width: 480px) {
    .overlay-content {
        margin-top: 60px;
    }

    .overlay-content h1 {
        font-size: 20px;
    }

    .hero-section {
        height: 50vh;
    }
}